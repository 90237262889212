document.addEventListener("DOMContentLoaded", function() {

    document.querySelectorAll("form.contact-form").forEach(form => {
        const submitButton = form.querySelector('button[type="submit"]');
        submitButton.addEventListener('click', function(event){
            event.preventDefault();

            const errorAlert = form.querySelector(".alert-danger");
            const successAlert = form.querySelector(".alert-success");
            const submitButton = form.querySelector("button[type=submit]");
            const spinnerButton = form.querySelector("button[type=button].spinner-button");

            if (submitButton && spinnerButton) {
                spinnerButton.style.width = `${submitButton.offsetWidth}px`;
                spinnerButton.style.height = `${submitButton.offsetHeight}px`;
                spinnerButton.querySelector("i").style.margin = "0";
            }

            if(errorAlert) errorAlert.style.display = "none";
            if(successAlert) successAlert.style.display = "none";
            form.querySelectorAll("input, select, textarea").forEach(field => field.classList.remove("has-error"));

            if(errorAlert) errorAlert.innerHTML = "";
            if(successAlert) successAlert.innerHTML = "";

            const formData = new FormData(form);
            formData.append("lang", Wolmart.lang);

            form.querySelectorAll("input[type=checkbox]:not(:checked)").forEach(checkbox => {
                if (checkbox.name !== "privacy") {
                    formData.append(checkbox.name, "off");
                }
            });

            if (submitButton && spinnerButton) {
                submitButton.classList.add("d-none");
                spinnerButton.classList.remove("d-none");
            }

            const xhr = new XMLHttpRequest();
            xhr.open("POST", "api/sendMessage.php", true);

            xhr.onreadystatechange = function() {
                if (xhr.readyState !== 4) return;

                const response = JSON.parse(xhr.responseText);

                if (response.success) {
                    if (response.redirect) {
                        window.location.href = response.redirect;
                        return;
                    }

                    if(successAlert) successAlert.style.display = "block";
                    if(successAlert) successAlert.innerHTML = response.message;
                    form.reset();
                } else {
                    response.errors.forEach(({ field, message }) => {
                        const inputField = form.querySelector(`[name='${field}']`);
                        inputField?.classList.add("has-error");
                        errorAlert?.insertAdjacentHTML("beforeend", `${message}<br>`);
                    });
                    if(errorAlert) errorAlert.style.display = "block";

                    const recaptcha = form.querySelector(".g-recaptcha");
                    recaptcha && grecaptcha.reset();
                }

                if (submitButton && spinnerButton) {
                    submitButton.classList.remove("d-none");
                    spinnerButton.classList.add("d-none");
                }
            };

            xhr.send(formData);
        })
    });

    // Modal Popup Handling
    const confirmBtn = document.getElementById("provider-confirm");
    const cancelBtn = document.getElementById("provider-cancel");
    let providerCallback = null;

    confirmBtn?.addEventListener("click", function() {
        $.magnificPopup.close();
        if (providerCallback) {
            providerCallback(true);
        }
    });

    cancelBtn?.addEventListener("click", function() {
        $.magnificPopup.close();
        if (providerCallback) {
            providerCallback(false);
        }
    });

    window.showProviderModal = function(title, message) {
        document.getElementById("provider-name").innerText = title;
        document.getElementById("provider-message").innerText = message;

        return new Promise((resolve) => {
            providerCallback = resolve;

            $.magnificPopup.open({
                items: {
                    src: '#providerModal',
                    type: 'inline'
                },
                modal: true
            });
        });
    };

    // Account Page Navigation
    document.querySelectorAll("[data-account-page-index]").forEach(element => {
        element?.addEventListener("click", function(e) {
            e.preventDefault();
            sessionStorage.setItem("accountPageIndex", this.getAttribute("data-account-page-index"));
            window.location.href = "account.html";
        });
    });
});
